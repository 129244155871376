import axios from 'axios';

const apiCall = async (url, method = 'GET', data = null, headers = {}) => {
    try {
        const response = await axios({
            url,
            method,
            headers: { 'Content-Type': 'application/json', ...headers },
            data
        });
        return response.data;
    } catch (error) {
        console.error('API call error:', error);
        throw error;
    }
};

export default apiCall

