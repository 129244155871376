const apiCallFetch = async (url, method = 'GET', data = null, headers = {}) => {

    try {
        const options = {
            method,
            headers: { 'Content-Type': 'application/json', ...headers },
        };

        if (data) {
            options.body = JSON.stringify(data);
        }

        const response = await fetch(url, options);
        //console.log(response);

        // if (response.status === 401) {
        //     localStorage.clear()
        //     window.location.href = '/login'; 
        //     // return;
        // }

        if (response.ok) {
            return await response.json();
        }
        //  else {
        //     throw new Error(`API call error: ${response}`);
        // }
    } catch (error) {
        console.error('API call error:', error);
        throw error;
    }
};

export default apiCallFetch;
