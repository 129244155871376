import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import ScrollToTop from './ScrollToTop';


import 'react-toastify/dist/ReactToastify.css';
import { SOCKET_URL, SocketContext } from "./utils/socket/socketProvider";
import { io } from "socket.io-client";


const Dashboard = lazy(() => import('./pages/employer/Dashboard'));
const Terms = lazy(() => import('./pages/Terms'));
const Coockies = lazy(() => import('./pages/Coockies'));
const About = lazy(() => import('./pages/About'));
const ApplicantProfile = lazy(() => import('./pages/employer/ApplicantProfile'));
const JobApplicant = lazy(() => import('./pages/employer/JobApplicant'));
const PaymentStatus = lazy(() => import('./components/PaymentStatus'));
const EMPPaymentStatus = lazy(() => import('./components/EMPPaymentStatus'));
const Privacy = lazy(() => import('./pages/Privacy'));
const JobDetails = lazy(() => import('./pages/employer/JobDetails'));
const EditJob = lazy(() => import('./pages/employer/EditJob'));
const MyJobs = lazy(() => import('./pages/employer/MyJobs'));
const MyPlan = lazy(() => import('./pages/employer/MyPlan'));
const Review = lazy(() => import('./pages/employer/Review'));
const EMPSubscription = lazy(() => import('./pages/employer/Subscriptions'));
const EMPProfile = lazy(() => import('./pages/employer/Profile'));
const EMPChat = lazy(() => import('./pages/employer/Chat'));
const EMPNotifications = lazy(() => import('./pages/employer/Notification'));
const CreateJob = lazy(() => import('./pages/employer/CreateJob'));
const Plans = lazy(() => import('./pages/jobSeeker/Plans'));
const Favourite = lazy(() => import('./pages/jobSeeker/Favourite'));
const Jobs = lazy(() => import('./pages/jobSeeker/Jobs'));
const CreateCompanyProfile = lazy(() => import('./pages/employer/CreateCompanyProfile'));
const Home = lazy(() => import('./pages/Home'));
const JobListing = lazy(() => import('./pages/JobListing'));
const FindJobs = lazy(() => import('./pages/FindJobs'));
const Blog = lazy(() => import('./pages/Blog'));
const BlogDetails = lazy(() => import('./pages/BlogDetails'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Companies = lazy(() => import('./pages/Companies'));
const LoginSeaker = lazy(() => import('./pages/jobSeeker/Login'));
const LoginEmployer = lazy(() => import('./pages/employer/Login'));
const MobileOTP = lazy(() => import('./pages/jobSeeker/MobileOTP'));
const OTPSuccess = lazy(() => import('./pages/jobSeeker/OTPSuccess'));
const AadharVerification = lazy(() => import('./pages/jobSeeker/AadharVerification'));
const AadharOTP = lazy(() => import('./pages/jobSeeker/AadharOTP'));
const AadharSucess = lazy(() => import('./pages/jobSeeker/AadharSucess'));
const Profile = lazy(() => import('./pages/jobSeeker/Profile'));
const Chat = lazy(() => import('./pages/jobSeeker/Chat'));
const Notification = lazy(() => import('./pages/jobSeeker/Notification'));
const Subscriptions = lazy(() => import('./pages/jobSeeker/Subscriptions'));
const EmpMobileOTP = lazy(() => import('./pages/employer/MobileOTP'));
const EmpOTPSucess = lazy(() => import('./pages/employer/OTPSuccess'));


export const xy = io(SOCKET_URL, {
  reconnectionDelay: 1000,
  reconnect: true,
  jsonp: false,
  transports: ["websocket"],
});


function App() {

  const getSocket = () => {
    let x = io(SOCKET_URL, {
      reconnectionDelay: 1000,
      reconnect: true,
      jsonp: false,
      transports: ["websocket"],
    });
    return x;
  };

  return (
    <SocketContext.Provider value={{ socketRef: getSocket() }}>
      <Router>
        <ScrollToTop />
        <Layout>
          <Suspense fallback={    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/joblisting" element={<JobListing />} />
              <Route path="/findjobs" element={<FindJobs />} />
              <Route path="/blogs" element={<Blog />} />
              <Route path="/blog/:id" element={<BlogDetails />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/companies" element={<Companies />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
              <Route path="/cookies_policy" element={<Coockies />} />
              

              <Route path="/about-us" element={<About />} />

              {/* job seaker routes */}
              <Route path="/login" element={<LoginSeaker />} />
              <Route path="/mobile-otp" element={<MobileOTP />} />
              <Route path="/verified-otp" element={<OTPSuccess />} />
              <Route path="/aadhar-verification" element={<AadharVerification />} />
              <Route path="/aadhar-otp" element={<AadharOTP />} />
              <Route path="/aadhar-success" element={<AadharSucess />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="/notifications" element={<Notification />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route path="/myplan" element={<Plans />} />
              <Route path="/myfavourite" element={<Favourite />} />
              <Route path="/myjobs" element={<Jobs />} />
              <Route path="/jobseeker/payment-status" element={<EMPPaymentStatus />} />


              {/* employer routes */}
              <Route path="/employer/login" element={<LoginEmployer />} />
              <Route path="/employer/mobile-otp" element={<EmpMobileOTP />} />
              <Route path="/employer/verified-otp" element={<EmpOTPSucess />} />
              <Route path="/employer/dashboard" element={<Dashboard />} />
              <Route path="/employer/create-profile" element={<CreateCompanyProfile />} />

              <Route path="/employer/create-job" element={<CreateJob />} />
              <Route path="/employer/edit-job" element={<EditJob />} />
              <Route path="/employer/chat" element={<EMPChat />} />
              <Route path="/employer/notifications" element={<EMPNotifications />} />
              <Route path="/employer/jobs" element={<MyJobs />} />
              <Route path="/employer/jobs/:id" element={<JobDetails />} />
              <Route path="/employer/jobs/:id/applicants" element={<JobApplicant />} />
              <Route path="/applicant/profile" element={<ApplicantProfile />} />
              <Route path="/employer/profile" element={<EMPProfile />} />
              <Route path="/employer/subscriptions" element={<EMPSubscription />} />
              <Route path="/employer/myplan" element={<MyPlan />} />
              <Route path="/employer/rating-and-review" element={<Review />} />

              {/* // In your routes: */}
              <Route path="/payment-status" element={<PaymentStatus />} />

            </Routes>
          </Suspense>
        </Layout>
      </Router>
    </SocketContext.Provider>
  );
}

export default App;

